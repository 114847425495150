import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql }  from 'gatsby';

import Layout from '../components/layout';

import Social  from '../pages/widgets/social';

export default function Template({
  data
}) {

  const { markdownRemark: post, site } = data;

  let { siteUrl } = site.siteMetadata;
  const shareUrl = siteUrl + '/' + post.fields.path;
  let { announcement } = post.frontmatter;

  return (
    <Layout>
      <div className="">

        <Helmet>
          <title>{post.frontmatter.title} | SwitchSmith</title>
          <link rel="canonical" href={shareUrl}/>
        </Helmet>

        <div className="row pt3">
          <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2"></div>
          <div className="col-xs-10 col-sm-10 col-md-2 col-lg-8">
            <h1>{post.frontmatter.title}</h1>
            <h5>{post.frontmatter.date}</h5>
            <div className="pt3">
              <Social shareUrl={shareUrl} announcement={announcement}/>
            </div>
            <div className="mt4" dangerouslySetInnerHTML={{ __html: post.html }} />
          </div>
        </div>

      </div>
    </Layout>
  );
}


export const query = graphql`
  query BlogPostByPath($id: String!) {

    site {
      siteMetadata {
        title
        description
        siteUrl
        site_url: siteUrl
      }
    }

    markdownRemark(id: { eq: $id } ) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        announcement
      }
      fields {
        path
      }
    }
  }
`
;
